@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'Open Sans', arial;
$boldFontFamily: 'Open Sans', arial;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #1B1B1B;
$linkColor: #007DBA;
$labelColor: #aaaaaa;
$formColor: #1B1B1B;
$utilColor: #007DBA;
$priceColor: #E3173E;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #1B1B1B;
$titleSecondaryColor: #ffffff;
$subtitleColor: #1B1B1B;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #E3173E;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #E3173E;
$colorBtnAlpha: #fff;
$bgBtnBeta: #007DBA;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #007DBA;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 5px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #E3173E;

$garageBackground: #ffffff;
$garageTextColor: #1B1B1B;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #21232e;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #007DBA;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #007DBA;
$progressBarCloseButtonBG: #007DBA;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========

@include breakpoint(mobile){
    .widget-sr{
        &.makes__honda{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}

.widget-sr{
    @include clearfix;
    width: 100%;
    &.makes__honda{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .listing-tile .listing-tile-drivePowerTrains{
            color: $textColor;
        }
        .catalog{
            background-color: #F2F2F2 !important;
            .vehicle-note__wrapper{
                background: #F2F2F2;
            }
        }
        .listing-used{
            background-color: #FFFFFF !important;
        }
        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: #FFFFFF !important;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #007DBA!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#007DBA 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #007DBA !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #007DBA !important;
            .icon,.icon-Crochet{
                color: #007DBA !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #007DBA;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #007DBA;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #007DBA !important;
            }
        }
        .doneLabel {
            color: #007DBA !important;
            .icon-Crochet{
                color: #007DBA !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #007DBA !important;
            }
        }
        .has-success-background{
            background-color: #007DBA !important;
        }
        .header-image{
            img{
                width: auto !important;
                max-width: 210px !important;
            }
        }
        .listing-tile-image{
            .car-tagline{
                color: #ffffff;
            }
        }
        .preview-photo-wrapper .preview-photo-text{
            background: #007DBA!important;
        }
        .sr-button-1{
            border: 1px solid $bgBtnAlpha !important;
            &:hover{
                background: #b11616 !important;
                opacity: 1!important;
            }
        }
        .sr-button-2{
            border: 1px solid $bgBtnBeta !important;
            &:hover{

                background: #ffffff !important;
                opacity: 1!important;
                color: $bgBtnBeta !important;
            }
        }

        .sr-button-1,
        .sr-button-2{
            border-radius: 5px;
        }


        .financing-section.tile-payment-info .tile-payment-detail,
        .listing-used-results .number-results,
        .listing-used-bodystyle,
        .listing-used-filters-title,
        .model-list li .model-label,
        .listing-used-brand .InputCheckbox label,
        .financing-section.tile-payment-info .tile-frequency,
        .tile-payment-amount span,
        .method-button-payment-bar button,
        .listing-used-filters-head-button a,
        .Select-value .Select-value-label,
        .vehicle-overview__vehicle-title .trim,
        .vehicle-name,
        .vehicle-cost .vehicle-price,
        .vehicle-payment-title,
        .new-car-name,
        .car-equipments_summary .specs-bloc,
        .listing-used-filters-head-title,
        .showroom-vdp-used-features-vehicle-info-value,
        .carfax-title,
        .certified-title{
            color: #1b1b1b!important;
        }

        .financing-section .tile-payment-rate,
        .listing-used-filters-bloc .metadata-list .metadata-button,
        .listing-used-filters .slider-labels,
        .listing-used-payment-wrap .styled-placeholder.dropdown.not-empty .input-label,
        .listing-button-wrapper .model-button,
        .listing-new-tile .listing-tile-vin,
        .listing-tile-specification-stock,
        .listing-tile-package-description,
        .vehicle-vinno,.vehicle-stockno,
        .tile-payment-rate .frequency-highlight,
        .section-showroom-header__model-listing-item.factory-order-label span,
        .listing-tile .payment-rebates .payment-rebate,
        .sr-disclaimer,
        .disclaimer,
        .TradeInTile--content-official-subtitle,
        .timeSaved,
        .new-car-motor p,
        .features-bloc--content{
            color: #555555 !important;
        }
        .vehicle-overview__optionalButton{
            .vehicle-cta-btn{
                width: auto !important;
            }
        }
        .financing-section.tile-payment-info .tile-payment-amount .tile-price{
            width: auto !important;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }

        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__honda{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}